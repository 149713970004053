<template lang="html">
    <div class="blogView">
        <!--<component :is="blogComp" :blog="form"></component>-->
    </div>
</template>

<script>
    //import blog from '@/components/BlogSkins/Default.vue'

    export default {
        data() {
            return {
                form:null,
              blogComp: {},
            }
        },
        created() {
            //this.loadData()
        },
        mounted() {
        },
        computed: {
        },
        watch: {
        },
        methods: {

            loadData() {
                //var _this = this
                //if (this.$route.params.blogId) {
                //    this.$axios.get('/Api/My/Blogs/' + _this.$route.params.blogId)
                //        .then(res => {
                //            _this.form = res.data;
                //        })
                //}
            },
        },
        computed: {
        },

        watch: {
        },
        components: {
        },
    }
</script>
<style>
    .blogedit .itemicon {
        width: 70px;
        height: 70px;
    }

        .blogedit .itemicon > .imgdiv {
            width: 100%;
            height: 100%;
            background-image: url('');
            background-size: cover;
        }

    .blogedit .itembody {
        width: calc(100% - 55px);
    }
</style>
